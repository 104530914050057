<template>
  <div class="asset-item" @click.stop="assetItemClick">
    <v-img :src="getUrlPicture" aspect-ratio="1" class="asset-item__image" />
    <div v-if="select" class="selected" />
    <div class="asset-item__tools" :style="backgroundColor">
      <div class="asset-item__tools--details">
        <small @click.stop="changeNameOpenConfirmDialog">{{
          item.name | truncate(100)
        }}</small>
      </div>
      <div class="asset-item__tools--actions">
        <v-icon @click.stop="openPreview"> $zoom_plus</v-icon>
        <v-icon @click.stop="openEditorImageDialog"> $brush</v-icon>
        <v-icon @click.stop="removeOpenConfirmDialog"> $trash-default</v-icon>
        <v-icon @click.stop="changeNameOpenConfirmDialog"> $pencil</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { hexToRgba, pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import { modeTypes } from "@/lib/calendesk-js-library/components/assets/assetsTypes";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import sharedDialogTypes from "@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "AssetItem",
  mixins: [sharedActions],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      select: null,
      modeTypes,
      disabledPreview: false,
    };
  },
  computed: {
    ...mapGetters({
      getMode: "assets/getMode",
      getSelected: "assets/getSelected",
    }),
    getUrlPicture() {
      return `${this.$config("image_base_url")}${this.item.default_image.name}`;
    },
    backgroundColor() {
      return {
        backgroundColor: hexToRgba(
          this.$vuetify.theme.themes.light.background,
          0.8
        ),
      };
    },
  },
  watch: {
    getSelected() {
      if (this.getSelected.id === this.item.id) {
        this.select = this.getSelected;
      } else {
        this.select = null;
      }
    },
  },
  methods: {
    ...mapMutations({
      addSelected: "assets/ADD_SELECTED",
      removeSelected: "assets/REMOVE_SELECTED",
      setSelected: "assets/SET_SELECTED",
      setImageEdit: "assets/SET_IMAGE_EDIT",
      setConfirmSelected: "assets/SET_CONFIRM_SELECTED",
    }),
    ...mapActions({
      openPreviewAsset: "assets/openPreviewAsset",
    }),
    assetItemClick() {
      if (this.getMode === modeTypes.NONE) {
        if (this.disabledPreview) {
          this.disabledPreview = false;
          return;
        }

        pushEvent("openPreviewAsset");

        this.openPreviewAsset(
          `${this.$config("image_base_url")}${this.item.default_image.name}`
        );
      } else {
        this.select = this.item;
        this.setSelected(this.select);
        this.setConfirmSelected(true);
        this.closeDialog();
      }
    },
    openPreview() {
      if (this.disabledPreview) {
        this.disabledPreview = false;
        return;
      }

      pushEvent("openPreviewAsset");

      this.openPreviewAsset(
        `${this.$config("image_base_url")}${this.item.default_image.name}`
      );
    },
    openEditorImageDialog() {
      pushEvent("openEditorImageDialog");

      this.disabledPreview = true;
      this.setImageEdit(this.item);
      if (this.getMode !== modeTypes.NONE) {
        this.select = this.item;
      }
      this.openDialog({
        type: sharedDialogTypes.EDITOR_IMAGE_DIALOG,
        title: this.$trans("editor_image"),
        size: dialogSize.FULL_SCREEN,
      });
    },
    changeNameOpenConfirmDialog() {
      this.disabledPreview = true;
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.CHANGE_NAME_ASSET,
        data: this.item,
      });
    },
    removeOpenConfirmDialog() {
      this.disabledPreview = true;
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.REMOVE_ASSET,
        data: this.item,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-item {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.selected {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 2px solid var(--v-primary-base);
  border-radius: 6px;
}

.asset-item__image {
  border-radius: 6px;
}

.asset-item__tools {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  padding: 0.75rem;
  border-radius: 6px;
  border: 2px solid transparent;
}

.asset-item:hover {
  .selected + .asset-item__tools {
    border: 2px solid var(--v-primary-base);
  }

  .asset-item__tools {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .asset-item__tools--details {
    span {
      word-break: break-all;
    }
  }

  .asset-item__tools--actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .v-icon {
    width: max-content;
    margin: 0 0.5rem;
    color: var(--v-dark-base);
    cursor: pointer;
  }
}
</style>
